import "./infobar.css";
import { Button, AButton, ButtonPrimary } from "../../ui/buttons";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { PositionPopupContainer, PopupHeader } from "../../ui/popup";
import { BonusSign } from "../item/item";
import { useEffect, useState } from "react";
import axios from "axios";

const MButton = styled(Button)`
    margin-left: 10px;
`

const AButtonMargin = styled(AButton)`

`

const arrowStyle = {
    color: "#0a69da"
}

export function InfoBar() {
    const [bonus, setBonus] = useState("")

    useEffect(() => {
        var phone = localStorage.getItem("phone")
        if (phone) {
            axios.post("https://phoneplaceserver.ru/users/getBonus", {"phone": phone}).then((response) => {
                setBonus(response.data["response"])
            })
        }
    }, [])

    return(
        <div id="infobar-body">
            <div id="infobar-content">
                <AButtonMargin href="tel:+7910540777">+79105400777</AButtonMargin>
                <Popup trigger={
                    <MButton>Реквизиты</MButton>
                } position={"bottom left"} arrowStyle={arrowStyle}>
                    <PositionPopupContainer>
                        <PopupHeader>Реквизиты</PopupHeader>
                        <div class="infobar-requisites">ИП Чанчилова Наталья Витальевна</div>
                        <div class="infobar-requisites">ИНН: 400703445540</div>
                        <div class="infobar-requisites">АО "АЛЬФА-БАНК"</div>
                        <div class="infobar-requisites">БИК: 044525593</div>
                        <div class="infobar-requisites">Номер счета: 40802810701480000617</div>
                        <div class="infobar-requisites">Кор. счет: 30101810200000000593</div>
                        <div class="infobar-requisites">Юридический адрес: улица Белкинская, д. 45, кв./оф. 14, Калужская область, г. Обнинск</div>
                    </PositionPopupContainer>
                </Popup>
                <MButton to="/reviews">Отзывы</MButton>
            </div>
        </div>
    )
}