import "./userPage.css"
import bonus from "../../ui/icons/bonus.png"
import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import noOrders from "./no-orders.png"

export function UserPage() {
    const [ orders, setOrders ] = useState([])
    const [ name, setName ] = useState("")  

    useEffect(() => {
        var phone = localStorage.getItem("phone")
        var password = localStorage.getItem("password")

        console.log(phone)
        console.log(password)

        axios.post("https://phoneplaceserver.ru/users/getOrdersId", {"phone": phone, "password": password}).then((response) => {
            setOrders(response.data["orders"].reverse())
        })

        axios.post("https://phoneplaceserver.ru/users/getName", {"phone": phone, "password": password}).then((response) => {
            setName(response.data["response"])
        })
    }, [])



    return (
        <div id="userpage-body">
            <div id="userpage-content">
                <div id="userpage-user">
                    <div id="userpage-pic">
                        <i class="fa-solid fa-user"></i>
                    </div>
                    {name}
                </div>
                <div id="userpage-order-history">
                    {orders.map((index, item) => (
                        <Order id={orders[item]}/>
                    ))}
                    {orders.length === 0 ?
                    <NoOrders/> : <div></div>

                    }
                </div>
            </div>
        </div>
    )
}

function NoOrders() {
    return (
        <div class="no-orders-sign">
            <img src={noOrders} alt=""/>
            <div class="no-orders-title">
                У вас еще небыло заказов
            </div>
        </div>
    )
}

function Order({id}) {
    const [items, setItems] = useState([])
    const [total, setTotal] = useState("")
    const [bonus, setBonus] = useState("")
    const [status, setStatus] = useState("")
    const [statusTitle, setStatusTitle] = useState("")

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/users/getOrder", {"id": id}).then((response) => {
            setItems(response.data["items"])
            setTotal(response.data["total"])
            setBonus(response.data["bonus"])
        })
    }, [id])

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/users/getOrderStatus", {"id": id}).then((response) => {
            setStatus(response.data["status"])

            if (response.data["status"] === "pending") {
                setStatusTitle("Обработка")
            } else if (response.data["status"] === "accepted") {
                setStatusTitle("Принят")
            } else if (response.data["status"] === "delivery") {
                setStatusTitle("В доставке")
            } else if (response.data["status"] === "delivered") {
                setStatusTitle("Доставлен")
            }

        })
    }, [id])

    return (
        <div class="userpage-order">
            <div class="userpage-order-header">
                <div class="userpage-order-number">
                    Заказ #{id}
                </div>
                <div class="userpage-order-status">
                    {statusTitle}
                </div>
            </div>
            { status === "pending" ? <DeliveryStatusPending/> : status === "accepted" ? <DeliveryStatusAccepted/> : 
            status === "delivery" ? <DeliveryStatusDelivery/> : status === "delivered" ? <DeliveryStatusDelivered/> : <div/>
                
            }
            <div class="userpage-order-items-images">
                {items.map((index, item) => (
                    <img src={"https://phoneplaceserver.ru/items/getImage?image=" + items[item]["image"]} alt=""/>
                ))}
            </div>
            <div class="userpage-order-items-list">
                {items.map((index, item) => (
                    <ListItem title={items[item]["title"]} price={items[item]["price"]} bonus={items[item]["bonus"]}/>
                ))}
            </div>
            <div class="userpage-order-items-total">

            </div>
        </div>
    )
}

function ListItem({title, price, bonus}) {
    return (
        <div class="userpage-order-item-list">
            <div class="userpage-order-item-list-price">
                <div>
                    {price} ₽
                </div>
                <div class="userpage-order-item-list-bonus">
                    <BonusSign amount={bonus}/>
                </div>
            </div>
            <div class="userpage-order-item-list-title">
                {title}
            </div>
        </div>
    )
}

export function DeliveryStatus() {
    return (
        <div class="delivery-status">
            <div class="delivery-status-circle">
                <i class="fa-solid fa-clock"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-box"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-car"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

export function DeliveryStatusPending() {
    return (
        <div class="delivery-status">
            <div class="delivery-status-circle">
                <i class="fa-solid fa-clock"></i>
            </div>
            <div class="delivery-status-line line-disabled"></div>
            <div class="delivery-status-circle circle-disabled">
                <i class="fa-solid fa-box"></i>
            </div>
            <div class="delivery-status-line line-disabled"></div>
            <div class="delivery-status-circle circle-disabled">
                <i class="fa-solid fa-car"></i>
            </div>
            <div class="delivery-status-line line-disabled"></div>
            <div class="delivery-status-circle circle-disabled">
                <i class="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

export function DeliveryStatusAccepted() {
    return (
        <div class="delivery-status">
            <div class="delivery-status-circle">
                <i class="fa-solid fa-clock"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-box"></i>
            </div>
            <div class="delivery-status-line line-disabled"></div>
            <div class="delivery-status-circle circle-disabled">
                <i class="fa-solid fa-car"></i>
            </div>
            <div class="delivery-status-line line-disabled"></div>
            <div class="delivery-status-circle circle-disabled">
                <i class="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

export function DeliveryStatusDelivery() {
    return (
        <div class="delivery-status">
            <div class="delivery-status-circle">
                <i class="fa-solid fa-clock"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-box"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-car"></i>
            </div>
            <div class="delivery-status-line line-disabled"></div>
            <div class="delivery-status-circle circle-disabled">
                <i class="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

export function DeliveryStatusDelivered() {
    return (
        <div class="delivery-status">
            <div class="delivery-status-circle">
                <i class="fa-solid fa-clock"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-box"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-car"></i>
            </div>
            <div class="delivery-status-line"></div>
            <div class="delivery-status-circle">
                <i class="fa-solid fa-check"></i>
            </div>
        </div>
    )
}

export const DeliveryStatusContainver = styled.div`
    background-color: #f6f8fa;
    padding: 0px 20px;
    @media(min-width: 600px) {
        display: none;
    }
`

function BonusSign({amount}) {
    return(
        <div class="bonus-sign">
            <img src={bonus} alt=""/>
            <div class="bonus-sign-text">
                {amount}
            </div>
        </div>
    )
}