import "./reviews.css";
import axios from "axios";
import { useEffect, useState } from "react"; 

export function ReviewsView() {
    const [ reviews, setReviews ] = useState([])

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/reviews/getAll").then((response) => {
            setReviews(response.data["reviews"])
        })
    }, [])

    return (
        <div id="reviews-view-body">
            <div id="reviews-view-content">
                {reviews.map((index, item) => (
                    <ReviewForView item={reviews[item]["item"]} text={reviews[item]["review"]}/>
                ))}
                <ReviewForView/>
            </div>
        </div>
    )
}

export function ReviewForView({item, text}) {
    return(
        <div class="reviewforview-body">
            <div>
                ⭐️⭐️⭐️⭐️⭐️
            </div>
            <div class="reviewforview-item">
                {item}
            </div>
            <div>
                {text}
            </div>
        </div>
    )
}