import "./perks.css";
import { SectionTitle } from "../../ui/sectionTitle";
import truck from "./icons/truck.png";
import wrench from "./icons/wrench.png";
import star from "./icons/star.png";
import tradein from "./icons/tradein.png";
import card from "./icons/card.png";

export function Perks() {
    return (
        <div id="perks-body">
            <SectionTitle>Наши преимущества</SectionTitle>
            <div id="perks-content">
                <Perk icon={truck} text={"Бесплатная доставка по г. Обнинск в течение часа."}/>
                <Perk icon={wrench} text={"Гарантия 1 год на все наши устройства."}/>
                <Perk icon={star} text={"Более 150 положительных отзывов."}/>
                <Perk icon={tradein} text={"Возможно сдать старое устройство в Trade-In."}/>
                <Perk icon={card} text={"Возможна оплата картой (по СБП) и с юр. лица без НДС."}/>
            </div>
        </div>
    )
}

function Perk({icon, text}) {
    return(
        <div className="perk">
            <img className="perk-icon" src={icon}/>
            <div className="perk-text">{text}</div>
        </div>
    )
}