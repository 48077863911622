import { useEffect } from "react"
import axios from "axios"

export function CheckSession() {
    const phone = localStorage.getItem("phone")
    const password = localStorage.getItem("password")

    useEffect(() => {
        if (phone != null && password != null) {
            axios.post("https://phoneplaceserver.ru/users/checkSession", {"phone": phone, "password": password}).then((response) => {
                if (response.data["response"] !== "ok") {
                    localStorage.removeItem("phone")
                    localStorage.removeItem("password")
                    window.location.reload()
                }
            })
        }

    }, [phone, password])

    useEffect(() => {
        checkVersion()
    }, [])

    return (
        <>
        </>
    )
}

export const checkVersion = () => {
    var lastVersion = localStorage.getItem("version")
    var currentVersion = "1.0"
    if (lastVersion === null || Number(lastVersion) < Number(currentVersion)) {
        localStorage.removeItem("cart")
    }

    localStorage.setItem("version", currentVersion)
}