import { useParams } from "react-router-dom";
import { ShowcaseBody, ShowcaseContent, Item } from "../../elements/item/item";
import axios from "axios";
import { useEffect, useState } from "react"; 
import { SonyModelsList } from "./modelsList";
import { CurrentModelTitle } from "./modelsList";
import { BackButton } from "../../ui/buttons";

export function SonyModels() {
    const [ items, setItems ] = useState([])
    const { model } = useParams()  

    useEffect(() => {
        setItems([])
        if (model === "all") {
            axios.post("https://phoneplaceserver.ru/items/getDevice", {"device": "Sony"}).then((response) => {
                setItems(response.data["response"])
            })
        } else {
            let getModel = "Sony " + model
            axios.post("https://phoneplaceserver.ru/items/getModel", {"model": getModel}).then((response) => {
                setItems(response.data["response"])
            })
        }
    }, [model])

    return (
        <>
        <BackButton/>
        <SonyModelsList/>
        <CurrentModelTitle original={"Sony"} model={model}/>
        <ShowcaseBody>
            <ShowcaseContent>
                {items.map((index, item) => (
                    <Item id={items[item]["id"]}/>
                ))}
                {items.length === 0 ? <>Пусто...</> : <></>}
            </ShowcaseContent>
        </ShowcaseBody>
        </>
    )
}