import "./sections.css"
import { BonusSign } from "../../elements/item/item"
import { Button } from "../../ui/buttons"
import styled from "styled-components"

import tradein from "./images/tradein.png"
import credit from "./images/credit.png"
import { useEffect, useState } from "react"
import Popup from "reactjs-popup"

const SectionButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    background-color: #0a69da;
    border: 1px solid #115dbf;
    color: white;
    margin-top: 10px;
    &:hover {
        background-color: #115dbf;
        color: white;
    }
`
const ASectionButton = styled.a`
    

    cursor: pointer;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    display: flex;
    padding: 7px 12px;
    border-radius: 15px;
    transition: scale 0.1s;
    user-selection: none;
    text-decoration: none;
    color: black;
    @media(min-width: 600px) {
        &:hover {
            background-color: #f3f4f6;
        }
        &:active {
            scale: 0.9;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: #f3f4f6;
            scale: 0.9;
        }
    }


    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    background-color: #0a69da;
    border: 1px solid #115dbf;
    color: white;
    margin-top: 10px;
    &:hover {
        background-color: #115dbf;
        color: white;
    }
`

export function PriceSection({price, id}) {
    const [ showPopup, setShowPopup ] = useState(false)

    const handleCart = () => {
        AddToCart(id)
        setShowPopup(true)
    }

    return (
        <div class="item-view-section-container">
            <div class="item-view-price-row">
                {price} ₽ <div class="item-view-price-bonus"><BonusSign price={price}/></div>
            </div>
            <div class="item-view-card-price">
                Цена по карте: {Number(price.replace(" ", "")) + Number(price.replace(" ", "")) / 100 * 7} ₽
            </div>
            <SectionButton onClick={() => {handleCart()}}>
                Добавить в корзину
            </SectionButton>
            <Popup open={showPopup} modal>
                <div class="item-view-section-container alert-section-shadow">
                    <div class="item-section-title">
                        Добавлено в корзину
                    </div>
                    <SectionButton onClick={() => {setShowPopup(false)}}>
                        Хорошо
                    </SectionButton>
                    <SectionButton onClick={() => {window.location.href = "/cart"}}>
                        В корзину
                    </SectionButton>
                </div>
            </Popup>
        </div>
    )
}

function AddToCart(id) {
    var currentCart = JSON.parse(localStorage.getItem("cart"))
    if (currentCart === null) {
        currentCart = []
        currentCart.push({
            "id":  id,
            "amount": 1
        })
    } else {
        var cartIds = []
        for (var i = 0; i < currentCart.length; i++) {
            if (typeof currentCart[i] !== Object) {
                localStorage.removeItem("cart")
            }
            cartIds.push(currentCart[i]["id"])
        }
        if (cartIds.includes(id)) {
            console.log("aaaa")
            for (var i = 0; i < currentCart.length; i++) {
                if (currentCart[i]["id"] === id) {
                    currentCart[i] = {
                        "id": id,
                        "amount": currentCart[i]["amount"] + 1
                    }
                }
            }
        } else {
            currentCart.push({
                "id":  id,
                "amount": 1
            })
        }
    }
    localStorage.setItem("cart", JSON.stringify(currentCart))
    console.log(currentCart)
}

export function CreditSection() {
    return (
        <div class="item-view-section-container trade-in-container">
            <div class="item-section-title trade-in-contents">
                Возможно в кредит
            </div>
            <img src={credit} class="item-section-tradein-image"/>
            <div class="item-section-text trade-in-contents">
                До 36 месяцев.
            </div>
        </div>
    )
}

export function CallSection() {
    return (
        <div class="item-view-section-container">
            <div class="item-section-title">
                Остались вопросы?
            </div>
            <div class="item-section-text">
                Ответим на все Ваши вопросы по телефону
            </div>
            <ASectionButton href="tel:+79105400777">
                Позвонить
            </ASectionButton>
        </div>
    )
}

export function TradeInSection() {
    return (
        <div class="item-view-section-container trade-in-container">
            <div class="item-section-title trade-in-contents">
                Trade-In
            </div>
            <img src={tradein} class="item-section-tradein-image"/>
            <div class="item-section-text trade-in-contents">
                Обменяйте старый iPhone на новый
            </div>
        </div>
    )
}

export function TelegramSection() {
    return (
        <div class="item-view-section-container">
            <div class="item-section-title">
                Как узнавать о скидках?
            </div>
            <div class="item-section-text">
                Подпишитесь на наш Telegram канал и узнавайте о скидках первыми.
            </div>
            <SectionButton onClick={() => {window.open("https://t.me/phoneplaceru")}}>
                Перейти
            </SectionButton>
        </div>
    )
}

export function SpecsSection({specs}) {
    const [ allKeys, setAllKeys ] = useState([])
    const [ allSpecs, setAllSpecs ] = useState({})

    useEffect(() => {
        setAllKeys(Object.keys(specs))
        setAllSpecs(specs)
    }, [specs])

    return (
        <div class="item-view-section-container">
            <div class="item-section-title">
                Характеристики
            </div>
            <div class="item-section-text">
            {allKeys.map((index, item) => (
                <CharLine title={allKeys[item]} value={allSpecs[allKeys[item]]}/>
            ))}
            </div>
        </div>
    )
}

function CharLine({title, value}) {
    return (
        <div class="charline">
            <div class="charline-title">
                {title}
            </div>
            <div>
                {value}
            </div>
        </div>
    )
}

export function DeliverySection() {
    return (
        <div class="item-view-section-container">
            <div class="item-section-title">
                Доставка
            </div>
            <div class="item-section-text">
                Обнинск - бесплатная доставка в течение часа
                <br/><br/>
                Ближайшие города - бесплатная доставка в течение дня
                <br/><br/>
                По всей России - отправка СДЭКом
            </div>
        </div>
    )
}

export function HaveQuestionsSection() {
    return (
        <div class="item-view-section-container">
            <div class="item-section-title">
                Остались вопросы?
            </div>
            <div class="item-section-text">
                Напишите нам, и мы поможем Вам с выбором.
            </div>
            <SectionButton onClick={() => {window.open("https://t.me/phoneplace_help")}}>
                Написать
            </SectionButton>
        </div>
    )
}