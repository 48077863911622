import { ShowcaseBody, ShowcaseContent, Item } from "../../elements/item/item";
import axios from "axios";
import { useEffect, useState } from "react"; 
import { CurrentModelTitle } from "./modelsList";
import { BackButton } from "../../ui/buttons";

export function AirpodsModels() {
    const [ items, setItems ] = useState([])

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/items/getDevice", {"device": "AirPods"}).then((response) => {
            setItems(response.data["response"])
        })
    }, [])

    return (
        <>
        <BackButton/>
        <CurrentModelTitle original={"AirPods"} model={"all"}/>
        <ShowcaseBody>
            <ShowcaseContent>
                {items.map((index, item) => (
                    <Item id={items[item]["id"]}/>
                ))}
                {items.length === 0 ? <>Пусто...</> : <></>}
            </ShowcaseContent>
        </ShowcaseBody>
        </>
    )
}