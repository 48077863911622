import "./entrance.css"
import { useEffect, useState } from "react" 
import Turnstile from "react-turnstile";
import axios from "axios"
import { ProceedButton } from "./entrance"

export function SignUpForm() {
    const [ phone, setPhone ] = useState("")
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ passwordRepeat, setPasswordRepeat ] = useState("")  
    const [ turnstile, setTurnstile ] = useState(false)

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (var i = 0; i < phone.length; i++) {
            if (!(phone[i] in allowedPhoneValues)) {
                setPhone(phone.replace(phone[i], ""))
            }
        }

        if (phone.length > 10) {
            setPhone(phone.slice(0, -1))
        }
    }, [phone])

    return(
        <>
            <div class="mixed-input mixed-left first-input">
                +7 <input id="sign-up-phone" type="tel" placeholder="Номер телефона" class="mixed-field-left" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
            </div>
            <input id="sign-up-name" placeholder="Имя" class="regular-field" value={name} onChange={(e) => {setName(e.target.value)}}/>
            <input id="sign-up-email" type="email" placeholder="Почта" class="regular-field" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            <input id="sign-up-password" type="password" placeholder="Пароль" class="regular-field" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
            <input id="sign-up-password-repeat" type="password" placeholder="Повторение пароля" class="regular-field" value={passwordRepeat} onChange={(e) => {setPasswordRepeat(e.target.value)}}/>
            <div id="sign-up-error-field"></div>
            <div class="turnstile">
                <Turnstile sitekey="0x4AAAAAAAVhZMBfOiEYVN1y" onVerify={() => {setTurnstile(true)}}/>
            </div>
            <ProceedButton onClick={() => {SignUpRequest(phone, name, email, password, passwordRepeat, turnstile)}}>
                Зарегистрироваться
            </ProceedButton> 
        </>
    )
}

function SignUpRequest(phone, name, email, password, passwordRepeat, turnstile) {
    document.getElementById("sign-up-phone").style.border = "1px solid #d0d7de"
    document.getElementById("sign-up-name").style.border = "1px solid #d0d7de"
    document.getElementById("sign-up-email").style.border = "1px solid #d0d7de"
    document.getElementById("sign-up-password").style.border = "1px solid #d0d7de"
    document.getElementById("sign-up-password-repeat").style.border = "1px solid #d0d7de"

    document.getElementById("sign-up-error-field").style.paddingTop = "0px"

    if (password !== passwordRepeat) {
        document.getElementById("sign-up-password").style.border = "1px solid red"
        document.getElementById("sign-up-password-repeat").style.border = "1px solid red"
        document.getElementById("sign-up-error-field").style.paddingTop = "20px"
        document.getElementById("sign-up-error-field").innerHTML = "Пароли не совпадают"
        return
    }

    if (phone.length !== 10) {
        document.getElementById("sign-up-phone").style.border = "1px solid red"
        document.getElementById("sign-up-error-field").style.paddingTop = "20px"
        document.getElementById("sign-up-error-field").innerHTML = "Некорректный номер телефона"
        return
    }

    if (!turnstile) {
        document.getElementById("sign-up-error-field").style.paddingTop = "20px"
        document.getElementById("sign-up-error-field").innerHTML = "Ошибка верификации. Попробуйте отключить VPN и перезагрузить страницу."
        return
    }

    if (email.length < 6) {
        document.getElementById("sign-up-error-field").style.paddingTop = "20px"
        document.getElementById("sign-up-error-field").innerHTML = "Некорректная почта"
        return
    }

    if (name.length === 0) {
        document.getElementById("sign-up-error-field").style.paddingTop = "20px"
        document.getElementById("sign-up-error-field").innerHTML = "Имя не указано"
        return
    }

    axios.post("https://phoneplaceserver.ru/users/sign_up", {"phone": phone, "name": name, "email": email, "password": password}).then((response) => {
        if (response.data["response"] === "success") {
            localStorage.setItem("phone", phone)
            localStorage.setItem("password", password)
            window.location.href = "/user"
        } else if (response.data["response"] === "busy phone") {
            document.getElementById("sign-up-error-field").style.paddingTop = "20px"
            document.getElementById("sign-up-error-field").innerHTML = "Номер телефона уже зарегистрирован"
        } else if (response.data["response"] === "busy email") {
            document.getElementById("sign-up-error-field").style.paddingTop = "20px"
            document.getElementById("sign-up-error-field").innerHTML = "Почта уже зарегистрирована"
        } else {
            document.getElementById("sign-up-error-field").style.paddingTop = "20px"
            document.getElementById("sign-up-error-field").innerHTML = "Ошибка. Попробуйте еще раз"
        }
    })
}

