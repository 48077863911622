import { Route, Routes } from "react-router-dom";
import { Header } from "./elements/header/header";
import { Home } from "./views/home/home";
import { MiniFooter } from "./elements/footer/miniFooter";
import { ReviewsView } from "./views/reviews/reviews";
import { User } from "./views/user/user";
import { NotificationSheet } from "./elements/notifications/sheet"
import { RegisterNotification } from "./elements/notifications/notifications"
import { CheckSession } from "./elements/checkSession/checkSession";
import { DeliveryStatusContainver, DeliveryStatus } from "./views/user/userPage";

import { IphoneModels } from "./views/models/iphone"; 
import { AirpodsModels } from "./views/models/airpods";
import { MacModels } from "./views/models/mac";
import { IpadModels } from "./views/models/ipad"  
import { WatchModels } from "./views/models/watch";  
import { SonyModels } from "./views/models/sony";
import { SamsungModels } from "./views/models/samsung"
import { StationModels } from "./views/models/station"
import { DysonModels } from "./views/models/dyson"
import { AccessoryModels } from "./views/models/accessory"
import { XiaomiModels } from "./views/models/xiaomi";

import { ItemView } from "./views/item/item";

import { Cart } from "./views/cart/cart";

import { Order } from "./views/order/order";
import { OrderFinished } from "./views/order/finished/finished";
import { useEffect } from "react";
import axios from "axios";

function App() {
  const phone = localStorage.getItem("phone")

  return (
    <>



    <script src="./credit.js"></script>

    



    <Header/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/user" element={<User/>}/>
      <Route path="/cart" element={<Cart/>}/>
      <Route path="/cart/order" element={<Order/>}/>
      <Route path="/cart/order/finished" element={<OrderFinished/>}/>
      <Route path="/reviews" element={<ReviewsView/>}/>
      <Route path="/iphone/:model/" element={<IphoneModels/>}/>
      <Route path="/airpods/:model/" element={<AirpodsModels/>}/>
      <Route path="/mac/:model/" element={<MacModels/>}/>
      <Route path="/ipad/:model/" element={<IpadModels/>}/>
      <Route path="/watch/:model" element={<WatchModels/>}/>
      <Route path="/sony/:model" element={<SonyModels/>}/>
      <Route path="/samsung/:model" element={<SamsungModels/>}/>
      <Route path="/station/:model" element={<StationModels/>}/>
      <Route path="/dyson/:model" element={<DysonModels/>}/>
      <Route path="/accessories/:model" element={<AccessoryModels/>}/>
      <Route path="/xiaomi/:model" element={<XiaomiModels/>}/>
      <Route path="/item/:id" element={<ItemView/>}/>
    </Routes>
    <MiniFooter/>

    <CheckSession/>
    </>
  );
}

//<NotificationSheet>
//{ phone === null ? <RegisterNotification/> : <></> }
//</NotificationSheet>

export default App;
