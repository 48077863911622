import "./order.css"
import { Button, ButtonPrimary } from "../../ui/buttons"
import styled from "styled-components"
import { useState, useEffect } from "react"
import axios from "axios"

const WideButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`

const WideButtonSelected = styled(WideButton)`
    color: #115dbf;
    outline: 1px solid #115dbf;
    background-color: #f6f8fa;
    border: 1px solid #115dbf;
`

const ProceedButton = styled(ButtonPrimary)`
    margin-top: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export function Order() {
    const [ deliveryType, setDeliveryType ] = useState("delivery") 

    useEffect(() => {
        console.log("asas")
    }, [])

    return (
        <div class="order-view-body">
            <div class="order-view-content">
                <div class="delivery-options">
                    { deliveryType === "delivery" ?
                    <WideButtonSelected>
                        Доставка
                    </WideButtonSelected> 
                    :
                    <WideButton onClick={() => {setDeliveryType("delivery")}}>
                        Доставка
                    </WideButton>
                    }
                    <span/>
                    { deliveryType === "pickup" ?
                    <WideButtonSelected>
                        Самовывоз
                    </WideButtonSelected> 
                    :
                    <WideButton onClick={() => {setDeliveryType("pickup")}}>
                        Самовывоз
                    </WideButton>
                    }
                </div>
                { deliveryType === "delivery" ?
                    <OrderWithDelivery/>
                    :
                    <OrderWithPickup/>
                }
            </div>
            
        </div>
    )
}

function OrderWithDelivery() {
    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("")
    const [ city, setCity ] = useState("")
    const [ street, setStreet ] = useState("")
    const [ entrance, setEntrance ] = useState("")
    const [ floor, setFloor ] = useState("")
    const [ flat, setFlat ] = useState("")
    const [ time, setTime ] = useState("")

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (var i = 0; i < phone.length; i++) {
            if (!(phone[i] in allowedPhoneValues)) {
                setPhone(phone.replace(phone[i], ""))
            }
        }

        if (phone.length > 10) {
            setPhone(phone.slice(0, -1))
        }
    }, [phone])

    useEffect(() => {
        if (localStorage.getItem("phone") !== null) {
            setPhone(localStorage.getItem("phone"))

            axios.post("https://phoneplaceserver.ru/users/getName", {"phone": localStorage.getItem("phone"), "password": localStorage.getItem("password")}).then((response) => {
                setName(response.data["response"])
            })

            axios.post("https://phoneplaceserver.ru/users/getAddress", {"phone": localStorage.getItem("phone"), "password": localStorage.getItem("password")}).then((response) => {
                const address = response.data["response"]
                setCity(address["city"])
                setStreet(address["street"])
                setEntrance(address["entrance"])
                setFloor(address["floor"])
                setFlat(address["flat"])
            })
        }
    }, [])

    const placeOrder = () => {
        const orderJson = {
            "type": "delivery",
            "address": {
                "city": city,
                "street": street,
                "entrance": entrance,
                "floor": floor,
                "flat": flat
            },
            "name": name,
            "phone": phone,
            "time": time,
            "items": JSON.parse(localStorage.getItem("orderItems"))
        }
        
        
        axios.post("https://phoneplaceserver.ru/users/place_order", orderJson).then((response) => {
            console.log(response.data)
            window.location.href = "/cart/order/finished"
        })

        localStorage.removeItem("cart")
        localStorage.removeItem("orderItems")
    }

    return (
        <>
            <div>
                <input placeholder="Имя" value={name} onChange={(e) => {setName(e.target.value)}}/>
                <div class="mixed-input">
                    +7 <input placeholder="Номер телефона" type="tel" class="mixed-field-left" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
                </div>
                <input placeholder="Город" value={city} onChange={(e) => {setCity(e.target.value)}}/>
                <input placeholder="Улица" value={street} onChange={(e) => {setStreet(e.target.value)}}/>
                <input placeholder="Подъезд" value={entrance} onChange={(e) => {setEntrance(e.target.value)}}/>
                <input placeholder="Этаж" value={floor} onChange={(e) => {setFloor(e.target.value)}}/>
                <input placeholder="Квартира" value={flat} onChange={(e) => {setFlat(e.target.value)}}/>
                <input placeholder="Удобное для Вас время" value={time} onChange={(e) => {setTime(e.target.value)}}/>
            </div>
            <div class="delivery-method">
                Мы свяжемся с Вами для уточнения способа доставки.
            </div>
            <ProceedButton onClick={() => {placeOrder()}}>Оформить</ProceedButton>
        </>
    )
}

function OrderWithPickup() {
    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("") 

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (var i = 0; i < phone.length; i++) {
            if (!(phone[i] in allowedPhoneValues)) {
                setPhone(phone.replace(phone[i], ""))
            }
        }

        if (phone.length > 10) {
            setPhone(phone.slice(0, -1))
        }
    }, [phone])

    useEffect(() => {
        if (localStorage.getItem("phone") !== null) {
            setPhone(localStorage.getItem("phone"))

            axios.post("https://phoneplaceserver.ru/users/getName", {"phone": localStorage.getItem("phone"), "password": localStorage.getItem("password")}).then((response) => {
                setName(response.data["response"])
            })
        }
    }, [])

    const placeOrder = () => {
        const orderJson = {
            "type": "pickup",
            "name": name,
            "phone": phone,
            "items": JSON.parse(localStorage.getItem("orderItems"))
        }
        
        if (localStorage.getItem("orderItems") !== null) {
            axios.post("https://phoneplaceserver.ru/users/place_order", orderJson).then((response) => {
                console.log(response.data)
                window.location.href = "/cart/order/finished"
            })

            localStorage.removeItem("cart")
            localStorage.removeItem("orderItems")
        }
    }

    return (
        <>
            <div>
                <input placeholder="Имя" value={name} onChange={(e) => {setName(e.target.value)}}/>
                <div class="mixed-input">
                    +7 <input placeholder="Номер телефона" type="tel" class="mixed-field-left" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
                </div>
                <div class="where-pickup">
                    Самовывоз в г. Обнинск, ул. Гагарина 71.
                </div>
                <div class="pickup-time">
                    Мы свяжемся с Вами для уточнения времени.
                </div>
            </div>
            
            <ProceedButton onClick={() => {placeOrder()}}>Оформить</ProceedButton>
        </>
    )
}