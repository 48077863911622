import styled from "styled-components";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const Button = styled(Link)`
    cursor: pointer;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    display: flex;
    padding: 7px 12px;
    border-radius: 15px;
    transition: scale 0.1s;
    user-selection: none;
    text-decoration: none;
    color: black;
    @media(min-width: 600px) {
        &:hover {
            background-color: #f3f4f6;
        }
        &:active {
            scale: 0.9;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: #f3f4f6;
            scale: 0.9;
        }
    }
`

export const AButton = styled.a`
    cursor: pointer;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    display: flex;
    padding: 7px 12px;
    border-radius: 15px;
    transition: scale 0.1s;
    user-selection: none;
    text-decoration: none;
    color: black;
    @media(min-width: 600px) {
        &:hover {
            background-color: #f3f4f6;
        }
        &:active {
            scale: 0.9;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: #f3f4f6;
            scale: 0.9;
        }
    }
`

export const ButtonPrimary = styled(Button)`
    background-color: #0a69da;
    border: 1px solid #115dbf;
    color: white;
    @media(min-width: 600px) {
        &:hover {
            background-color: #115dbf;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: #115dbf;
        }
    }
`

export const InlineButton = styled(Button)`
    background-color: transparent;
    border: none;
    color: #0a69da;
    text-decoration: none;
    @media(min-width: 600px) {
        &:hover {
            text-decoration: underline;
            background-color: transparent;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: transparent;
        }
    }
`

const BackButtonBody = styled.a`
    margin: 10px 0px;
    display: flex;
    justify-content: center;
`

const BackButtonContainer = styled.a`
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding-left: 10px;
`

const BackButtonItem = styled.a`
    cursor: pointer;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    display: flex;
    padding: 7px 12px;
    border-radius: 15px;
    transition: scale 0.1s;
    user-selection: none;
    text-decoration: none;
    color: black;
    @media(min-width: 600px) {
        &:hover {
            background-color: #f3f4f6;
        }
        &:active {
            scale: 0.9;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: #f3f4f6;
            scale: 0.9;
        }
    }
`

export function BackButton() {
    const [show, setShow] = useState(false)

    useEffect(() => {
        console.log(window.history.length)
        if (window.history.length <= 2) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [])

    return (
        <>
        { show === true ?
            <BackButtonBody>
                <BackButtonContainer>
                    <BackButtonItem href="javascript:window.history.back()">
                        <i class="fa-solid fa-chevron-left"></i><span/> Назад
                    </BackButtonItem>
                </BackButtonContainer>
            </BackButtonBody>
            : <></>
        }
        </>
    )
}

//background: ffffff
//secondary: f6f8fa
//secondary focused: f3f4f6
//border: d0d7de
//action: 0a69da
//action border: 115dbf