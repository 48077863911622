import { useEffect, useState } from "react"
import "./item.css"
import { Swiper } from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { useParams } from "react-router-dom"
import axios from "axios"

import { Sections } from "../../elements/sections/sections"

import { PriceSection, TradeInSection, TelegramSection, SpecsSection, DeliverySection, HaveQuestionsSection, CreditSection, CallSection } from "./sections"
import { ItemAvailableSign, ItemDeliverySign, ItemUnavailableSign } from "../../elements/item/item"

import { ShowcaseBody, ShowcaseContent, Item } from "../../elements/item/item"

import { BackButton } from "../../ui/buttons"

export function ItemView() {
    const { id } = useParams()

    const [ title, setTitle ] = useState("...")
    const [ photos, setPhotos ] = useState([]) 
    const [ price, setPrice ] = useState("...")
    const [ specs, setSpecs ] = useState({})
    const [ avaibility, setAvaibility ] = useState("")  

    const [ similar, setSimilar ] = useState([])

    useEffect(() => {
        const swiper = new Swiper(".images-swiper", {
            slidesPerView: 1,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    }, [photos.length])

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/items/getItem", {"id": id}).then((response) => {
            setTitle(response.data["title"])
            setPhotos(response.data["photos"])
            setAvaibility(response.data["avaibility"])
            setPrice(response.data["price"])
            setSpecs(response.data["specs"])
        })
    }, [])

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/items/getSimilar", {"id": id}).then((response) => {
            setSimilar(response.data["response"])
        })
    }, [])

    return (
        <>
        <BackButton/>
        <div class="item-view-body">
            <div class="item-view-content">
                <div class="item-view-side item-view-first-side">
                    <div class="outer-swiper">
                        {photos.length > 0 ?
                            <div class="images-swiper">
                                <div class="swiper-wrapper">
                                    {photos.map((index, item) => (
                                        <div class="swiper-slide">
                                            <div class="swiper-image-wrapper">
                                                <img src={"https://phoneplaceserver.ru/items/getImage?image=" + photos[item]}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div class="swiper-pagination"></div>
                                <div class="swiper-button-prev"><i class="fa-solid fa-chevron-left swiper-btn"></i></div>
                                <div class="swiper-button-next"><i class="fa-solid fa-chevron-right swiper-btn"></i></div>
                            </div> : <div></div>
                        }
                    </div>
                    
                </div>
                <div class="item-view-side item-view-second-side">
                    <div class="item-view-item-title">{title}</div>
                    <div class="item-view-avaibility">
                        { avaibility === "available" ? 
                            <ItemAvailableSign/> : avaibility === "delivery" ? <ItemDeliverySign/> : <ItemUnavailableSign/>
                        }
                    </div>
                    <PriceSection price={price} id={id}/>
                    <CreditSection/>
                    <CallSection/>
                    {title.search("Apple iPhone") !== -1 ? <TradeInSection/> : <div/>}
                    <TelegramSection/>
                    <SpecsSection specs={specs}/>
                    <DeliverySection/>
                    <HaveQuestionsSection/>
                </div>
            </div>
        </div> 
        <Sections/>
        <ShowcaseBody>
            <ShowcaseContent>
                {similar.map((index, item) => (
                    <Item id={similar[item]["id"]}/>
                ))}
            </ShowcaseContent>
        </ShowcaseBody>
        </>
    )
}