import styled from "styled-components";
import "./Item.css";
import axios from "axios";
import { useEffect, useState } from "react";
import bonus from "../../ui/icons/bonus.png"

export const ShowcaseBody = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

export const ShowcaseContent = styled.div`
    width: 100%;
    max-width: 1190px;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
`

export function ItemView({location}) {
    const [ items, setItems ] = useState([]) 

    useState(() => {
        axios.post("https://phoneplaceserver.ru/items/getShowcase", {"location": "main"}).then((response) => {
            setItems(response.data["showcase"])
        })
    }, []) 

    return (
        <ShowcaseBody>
            <ShowcaseContent>
                {items.map((index, item) => (
                    <Item id={items[item]}/>
                ))}
            </ShowcaseContent>
        </ShowcaseBody>
    )
}

export function Item({id}) {
    const [image, setImage] = useState("")
    const [name, setName] = useState("")
    const [avaibility, setAvability] = useState("")
    const [price, setPrice] = useState("")

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/items/getItem", {"id": id}).then((response) => {
            setImage(response.data["photos"][0])
            setName(response.data["title"])
            setAvability(response.data["avaibility"])
            setPrice(response.data["price"])
        })
    }, [id])
 
    return (
        <div className="item" onClick={() => {window.location.href = "/item/" + id}}>
            <img src={"https://phoneplaceserver.ru/items/getImage?image=" + image} alt={name}/>
            <div className="item-info">
                <div className="item-name">
                    {name}
                </div>
                
                <div className="item-bottom">
                    <div className="item-price">
                        {price} ₽
                    </div>
                    { avaibility === "available" ? <ItemAvailableSign/> :
                        <>
                            {avaibility === "delivery" ? <ItemDeliverySign/> : <ItemUnavailableSign/>}
                        </>
                    }
                </div>
                <div class="bonus-sign-container">
                    
                </div>
            </div>
        </div>
    )
}

//<BonusSign price={price}/>

export function BonusSign({price, amount}) {
    const [ bonusAmount, setBonusAmount ] = useState("")  

    useEffect(() => {
        if (price) {
            var intPrice = parseInt(Number(price.replace(" ", "")) / 300)
            setBonusAmount(intPrice)
        } else if (amount) {
            setBonusAmount(amount)
        }
    }, [price, amount])

    return(
        <div class="bonus-sign">
            <img src={bonus} alt=""/>
            <div class="bonus-sign-text">
                {bonusAmount}
            </div>
        </div>
    )
}

export function ItemAvailableSign() {
    return (
        <div className="avaibility item-available">
            <i className="fa-solid fa-check"></i> В наличии
        </div>
    )
}

export function ItemDeliverySign() {
    return (
        <div className="avaibility item-delivery">
            <i class="fa-solid fa-truck"></i> Под заказ
        </div>
    )
}

export function ItemUnavailableSign() {
    return (
        <div className="avaibility item-unavailable">
            <i class="fa-solid fa-xmark"></i> Нет в наличии
        </div>
    )
}