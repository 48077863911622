import { useEffect, useState } from "react";
import { Entrance } from "./entrance/entrance";
import { UserPage } from "./userPage";

export function User() {
    const [ state, setState ] = useState("loading") 

    useEffect(() => {
        var phoneData = localStorage.getItem("phone")
        if (phoneData === null) {
            setState("logged off")
        } else {
            
        }
    }, [])

    return(
        <div class="user-body">
            <div class="user-content">
                { state === "logged off" ? <Entrance/> : <UserPage/>}
            </div>
        </div>
    )
}