import styled from "styled-components";

export const PositionPopupContainer = styled.div`
    display: flex;
    padding: 10px;
    border-radius: 15px;
    background-color: #f6f8fa;
    max-width: 50%;
    border: 1px solid #0a69da;
    flex-direction: column;
    @media(max-width: 600px) {
        max-width: 80%;
    }
`

export const PopupHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    padding-bottom: 10px;
`