import "./entrance.css"
import { useEffect, useState } from "react" 
import Turnstile from "react-turnstile";
import axios from "axios"
import { ProceedButton } from "./entrance"

export function SignInForm() {
    const [ phone, setPhone ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ turnstile, setTurnstile ] = useState(false)

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (var i = 0; i < phone.length; i++) {
            if (!(phone[i] in allowedPhoneValues)) {
                setPhone(phone.replace(phone[i], ""))
            }
        }

        if (phone.length > 10) {
            setPhone(phone.slice(0, -1))
        }
    }, [phone])

    return( 
        <>
            <div class="mixed-input first-input">
                +7 <input placeholder="Номер телефона" type="tel" class="mixed-field-left" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
            </div>
            <input placeholder="Пароль" type="password" class="regular-field" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
            <div id="sign-up-error-field"></div>
            <div class="turnstile">
                <Turnstile sitekey="0x4AAAAAAAVhZMBfOiEYVN1y" onVerify={() => {setTurnstile(true)}}/>
            </div>
            <ProceedButton onClick={() => SignInRequest(phone, password, turnstile)}>
                Войти
            </ProceedButton> 
        </>
    )
}

function SignInRequest(phone, password, turnstile) {
    if (!turnstile) {
        document.getElementById("sign-up-error-field").style.paddingTop = "20px"
        document.getElementById("sign-up-error-field").innerHTML = "Ошибка верификации. Попробуйте отключить VPN и перезагрузить страницу."
        return
    }

    axios.post("https://phoneplaceserver.ru/users/sign_in", {"phone": phone, "password": password}).then((response) => {
        if (response.data["response"] === "success") {
            localStorage.setItem("phone", phone)
            localStorage.setItem("password", password)
            window.location.href = "/user"
        } else if (response.data["response"] === "does not exist") {
            document.getElementById("sign-up-error-field").style.paddingTop = "20px"
            document.getElementById("sign-up-error-field").innerHTML = "Такого аккаунта не существует"
        } else if (response.data["response"] === "incorrect password") {
            document.getElementById("sign-up-error-field").style.paddingTop = "20px"
            document.getElementById("sign-up-error-field").innerHTML = "Неверный пароль"
        } else {
            document.getElementById("sign-up-error-field").style.paddingTop = "20px"
            document.getElementById("sign-up-error-field").innerHTML = "Ошибка. Попробуйте еще раз"
        }
    })
}

