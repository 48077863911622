import "./sections.css";
import styled from "styled-components";
import iphone from "./images/iphone.webp";
import airpods from "./images/airpods.webp";
import watch from "./images/watch.webp";
import samsung from "./images/samsung.webp";
import station from "./images/station.webp"
import dyson from "./images/dyson.webp";
import ipad from "./images/ipad.webp"
import mac from "./images/mac.webp";
import playstation from "./images/playstation.webp";
import accessories from "./images/accessories.webp";
import xiaomi from "./images/xiaomi.webp"
import vision from "./images/vision.webp"

import { Link } from "react-router-dom";

export function Sections() {
    return (
        <div id="sections-body">
            <div id="sections-content">
                <Section title={"iPhone"} image={iphone} to={"/iphone/all"}/>
                <Section title={"AirPods"} image={airpods} to={"/airpods/all"}/>
                <Section title={"Mac"} image={mac} to={"/mac/all"}/>
                <Section title={"iPad"} image={ipad} to={"/ipad/all"}/>
                <Section title={"Watch"} image={watch} to={"/watch/all"}/>
                <Section title={"Vision"} image={vision} to={"/item/1243"}/>
                <Section title={"PlayStation"} image={playstation} to={"/sony/all"}/>
                <Section title={"Samsung"} image={samsung} to={"/samsung/all"}/>
                <Section title={"Станция"} image={station} to={"/station/all"}/>
                <Section title={"Dyson"} image={dyson} to={"/dyson/all"}/>
                <Section title={"Xiaomi"} image={xiaomi} to={"/xiaomi/all"}/>
                <Section title={"Аксессуары"} image={accessories} to={"/accessories/all"}/>
            </div>
        </div>
    )
}

export function Section({title, image, to}) {
    return (
        <Link className="section-body" to={to}>
            <div className="section-title">
                {title}
            </div>
            <div className="section-image">
                <img draggable="false" src={image}/>
            </div>
        </Link>
    )
}