import styled from "styled-components";

export const NotificationSheet = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 400px;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 999999;
    align-items: flex-end;
    padding: 10px;
    pointer-events: none;
    @media(max-width: 600px) {
        max-width: 100%;
    }
`