import styled from "styled-components";

export const SectionTitle = styled.div`
    padding-left: calc((100vw - 1180px)/2);
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 14pt;
    @media(max-width: 1200px) {
        padding-left: 10px;
    }
`