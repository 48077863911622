import { InfoBar } from "../../elements/infobar/infobar"
import { Banner } from "../../elements/banner/banner"
import { Telegram } from "../../elements/telegram/telegram"
import { Sections } from "../../elements/sections/sections"
import { Perks } from "../../elements/perks/perks"
import { Reviews } from "../../elements/reviews/reviews"
import { ItemView } from "../../elements/item/item"

export function Home() {
    return(
        <>
            <InfoBar/>
            <Banner/>
            <Sections/>
            <Telegram/>
            <ItemView location="main"/>
            <Perks/>
            <Reviews/>
        </>
    )
}