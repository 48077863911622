import "./telegram.css";
import sale from "./sale.png";
import { ButtonPrimary } from "../../ui/buttons";

export function Telegram() {
    return(
        <div id="telegram-body">
            <div id="telegram-content">
                <div id="tg-info">
                    <div>
                        <img src={sale} id="tg-icon"/>
                    </div>
                    <div id="tg-text">
                        <div id="tg-title">
                            Узнавайте о скидках первыми 
                        </div>
                        <div id="tg-desc">
                            Подпишитесь на наш канал в Telegram
                        </div>
                    </div>
                </div>
                <ButtonPrimary id="tg-button" onClick={() => {window.open("https://t.me/phoneplaceru")}}>Перейти</ButtonPrimary>
            </div>
        </div>
    )
}