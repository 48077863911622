import "./finished.css"
import { Button } from "../../../ui/buttons"

export function OrderFinished() {
    return (
        <div class="thanks-for-order">
            <div class="thanks-icon">
                <i class="fa-solid fa-check"></i>    
            </div>
            <div class="thanks-title">
                Спасибо за заказ!
            </div>
            <div class="thanks-text">
                Мы свяжемся с вами для подтверждения заказа
            </div>
            <Button onClick={() => {window.location.href = "/"}}>На главную</Button>
        </div>
    )
}