import "./entrance"
import { Button, ButtonPrimary } from "../../../ui/buttons" 
import { useState } from "react" 
import styled from "styled-components"
import { SignUpForm } from "./signUp"
import { SignInForm } from "./signIn"

export const WideButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`

const WideButtonSelected = styled(WideButton)`
    color: #115dbf;
    outline: 1px solid #115dbf;
    background-color: #f6f8fa;
    border: 1px solid #115dbf;
`

export const ProceedButton = styled(ButtonPrimary)`
    margin-top: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
`

export function Entrance() {
    const [ selectedButton, setSelectedButton ] = useState("sign in")
    return (
        <div class="signuporsignin-body">
            <div class="signuporsignin-content">
                <div class="signuporsignin-logo">
                    ph.
                </div>
                <div class="signinorsignup-selector">
                    { selectedButton === "sign in" ? <WideButtonSelected>Войти</WideButtonSelected> : <WideButton onClick={() => {setSelectedButton("sign in")}}>Войти</WideButton> }
                    <span/>
                    { selectedButton === "sign up" ? <WideButtonSelected>Регистрация</WideButtonSelected> : <WideButton onClick={() => {setSelectedButton("sign up")}}>Регистрация</WideButton> }
                </div>
                { selectedButton === "sign up" ? <SignUpForm/> : <SignInForm/>}
            </div>
        </div>
    )
}