import "./banner.css";
import banner from "./banner.webp";
import apple_logo from "./apple-logo.webp"
import { ButtonPrimary } from "../../ui/buttons";

export function Banner() {
    return (
        <div id="banner-container">
            <div id="banner-content">
                <div id="banner-body"> 
                    <img src={banner} id="banner-image" draggable="false" alt="Apple Vision Pro"/>
                    <div id="banner-contents">
                        <div id="banner-title">
                            <img src={apple_logo} id="banner-logo" draggable="false" alt="Apple Logo"/>
                            iPhone 16
                        </div>
                        <div id="banner-caption">
                            Доступен для заказа в<div class="banner-logo">phoneplace</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

//<img src={apple_logo} id="banner-logo" draggable="false" alt="Apple Logo"/>