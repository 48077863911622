import styled from "styled-components"
import "./header.css";
import { Button } from "../../ui/buttons";

const Logo = styled(Button)`
    font-family: "Unbounded";
    border: none;
    font-size: 13pt;
    background-color: transparent;
`

export function Header() {
    return (
        <div id="header-body">
            <div id="header-content">
                <Logo to="/">phoneplace</Logo>
                <div id="header-buttons">
                    <Logo to="/cart">
                        <i className="fa-solid fa-cart-shopping"></i>
                    </Logo>
                </div>
            </div>
        </div>
    )
}