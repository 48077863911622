import "./reviews.css";
import { InlineButton } from "../../ui/buttons";
import { SectionTitle } from "../../ui/sectionTitle"  
import { useEffect, useState } from "react";
import axios from "axios";

export function Reviews() {
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/reviews/getSome").then((response) => {
            setReviews(response.data["reviews"])
        })
    }, [])

    return (
        <div id="reviews-body">
            <div id="reviews-header">
                <SectionTitle>Отзывы</SectionTitle>
                <InlineButton id="all-reviews-button" to="/reviews">
                    Все отзывы
                </InlineButton>
            </div>
            <div id="reviews-content">
                {reviews.map((index, item) => (
                    <Review key={index} item={reviews[item]["item"]} text={reviews[item]["review"]}/>
                ))}
            </div>
        </div>
    )
}

function Review({item, text}) {
    const [itm, setItm] = useState("...")
    const [txt, setTxt] = useState("...")

    useEffect(() => {
        if (item.length > 40) {
            setItm(item.slice(0,40) + "...")
        } else {
            setItm(item)
        }

        if (text.length > 250) {
            setTxt(text.slice(0, 250) + "...")
        } else {
            setTxt(text)
        }
    })

    return( 
        <div className="review">
            <div className="review-text">
                ⭐️⭐️⭐️⭐️⭐️
            </div>
            <div className="review-item">
                {itm}
            </div>
            <div className="review-text">
                {txt}
            </div>
        </div>
    )
}