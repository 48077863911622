import "./cart.css"
import { createContext, useEffect, useState } from "react"
import bonus from "../../ui/icons/bonus.png"
import styled from "styled-components"
import { Button } from "../../ui/buttons"

import { BonusSign, ItemView } from "../../elements/item/item"

import emptyCart from "./empty_cart.png"

import axios from "axios"

import { checkVersion } from "../../elements/checkSession/checkSession"

const SectionButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    background-color: #0a69da;
    border: 1px solid #115dbf;
    color: white;
    margin-top: 10px;
    &:hover {
        background-color: #115dbf;
        color: white;
    }
`

const ItemViewButton = styled(Button)`
    font-size: 10pt;
`

const WideButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`

const WideButtonSelected = styled(WideButton)`
    color: #115dbf;
    outline: 1px solid #115dbf;
    background-color: #f6f8fa;
    border: 1px solid #115dbf;
`

export function Cart() {
    const [ cart, setCart ] = useState([])
    const [ total, setTotal ] = useState("0")

    useEffect(() => {
        checkVersion()
        var jsonCart = localStorage.getItem("cart")
        var cartObject = JSON.parse(jsonCart)
        if (cartObject === null) {
            cartObject = []
        }
        setCart(cartObject)
        handleTotal()
    }, [])

    const handleTotal = async () => {
        var cartObject = JSON.parse(localStorage.getItem("cart"))
        if (cartObject === null) {
            cartObject = []
       }
        var totalPrice = 0
        for (var i = 0; i < cartObject.length; i++) {
            await axios.post("https://phoneplaceserver.ru/items/getPrice", {"id": cartObject[i]["id"]}).then((response) => {
                totalPrice = totalPrice + (Number(response.data["response"].replace(" ", "")) * cartObject[i]["amount"])
            })
        }
        setTotal(String(totalPrice))
    }

    const handleDelete = (id) => {
        var jsonCart = localStorage.getItem("cart")
            var cartObject = JSON.parse(jsonCart)
            if (cartObject === null) {
                cartObject = []
            }
            var newCartObject = []
            for (var i = 0; i < cartObject.length; i++ ) {
                if (cartObject[i]["id"] != id) {
                    newCartObject.push(cartObject[i])
                }
            }
            localStorage.setItem("cart", JSON.stringify(newCartObject))
            setCart(newCartObject)
            handleTotal()
    }

    return (
        <div class="cart-view-body">
            { cart.length > 0 ? 
            <div class="cart-view-content">
                <div class="cart-view-first-side">
                    {cart.map((index, item) => (
                        <CartItem id={cart[item]["id"]} cartAmount={cart[item]["amount"]} handleDelete={handleDelete} handleTotal={handleTotal}/>
                    ))}
                </div>
                <div class="cart-view-second-side">
                    <ProceedSection total={total}/>
                    <CreditSection/>
                </div>
                
            </div> : <EmptyCartView/> }
        </div>
    )
}

function EmptyCartView() {
    return (
        <div class="empty-cart-view">
            <img src={emptyCart} class="empty-cart-view-image"/>
            <div class="empty-cart-view-title">
                В корзине пусто...
            </div>
            <div class="empty-cart-view-text">
                Самое время что-нибудь добавить
            </div>
            <Button to="/">На главную</Button>
        </div>
    )
}

function CartItem({id, handleDelete, cartAmount, handleTotal}) {
    const [ title, setTitle ] = useState("...")
    const [ price, setPrice ] = useState("0")
    const [ image, setImage ] = useState("")  
    const [ amount, setAmount ] = useState(cartAmount)

    useEffect(() => {
        axios.post("https://phoneplaceserver.ru/items/getItem", {"id": id}).then((response) => {
            setTitle(response.data["title"])
            setPrice(response.data["price"])
            setImage(response.data["photos"][0])
        })
    }, [id])

    return (
        <div class="cart-item">
            <img src={"https://phoneplaceserver.ru/items/getImage?image=" + image} onClick={() => {window.location.href = "/item/" + id}}/>
            <div class="cart-item-info">
                <div onClick={() => {window.location.href = "/item/" + id}}>
                    {title}
                </div>
                <div class="cart-item-footer">
                    <div class="cart-item-price">
                    {price} ₽ <div class="cart-item-bonus"><BonusSign price={price}/></div>
                    </div>
                   
                </div>
                <div class="cart-item-buttons">
                    <Counter amount={amount} id={id} handleTotal={handleTotal}/>
                    <ItemViewButton onClick={() => {handleDelete(id)}}>Удалить</ItemViewButton>
                </div>
            </div>
        </div>
    )
}

function Counter({amount, id, handleTotal}) {
    const [ count, setCount ] = useState(amount)

    useEffect(() => {
        if (count < 1) {
            var jsonCart = localStorage.getItem("cart")
            var cartObject = JSON.parse(jsonCart)
            if (cartObject === null) {
                cartObject = []
            }
            var newCartObject = []
            for (var i = 0; i < cartObject.length; i++ ) {
                if (cartObject[i]["id"] != id) {
                    newCartObject.push(cartObject[i])
                }
            }
            localStorage.setItem("cart", JSON.stringify(newCartObject))
            window.location.reload()
        } else {
            var newCartItem = {
                "id": id,
                "amount": count
            }
            var jsonCart = localStorage.getItem("cart")
            var cartObject = JSON.parse(jsonCart)
            if (cartObject === null) {
                cartObject = []
            }
            var newCartObject = []
            for (var i = 0; i < cartObject.length; i++) {
                if (cartObject[i]["id"] === newCartItem["id"]) {
                    newCartObject.push(newCartItem)
                } else {
                    newCartObject.push(cartObject[i])
                }
            }
            localStorage.setItem("cart", JSON.stringify(newCartObject))
            handleTotal()
        }
    }, [count])

    return (
        <div class="counter">
            <ItemViewButton onClick={() => {setCount(count - 1)}}>
                -
            </ItemViewButton>
            <div class="counter-number">
                {count}
            </div>
            <ItemViewButton onClick={() => {setCount(count + 1)}}>
                +
            </ItemViewButton>
        </div>
    )
}
 
function ProceedSection({total}) {
    const [method, setMethod] = useState("cash")

    const handleProceed = async () => {
        var cartObject = JSON.parse(localStorage.getItem("cart"))
        console.log(cartObject)
        var allItems = []
        var totalPrice = 0
        var totalBonus = 0

        for (var i = 0; i < cartObject.length; i++) {
            await axios.post("https://phoneplaceserver.ru/items/getPrice", {"id": cartObject[i]["id"]}).then((response) => {
                var price = response.data["response"]
                var bonus = parseInt(Number(price.replace(" ", "")) / 300)

                var thisJson = {
                    "id": cartObject[i]["id"],
                    "price": price,
                    "amount": cartObject[i]["amount"],
                    "bonus": String(bonus)
                }

                totalPrice = totalPrice + Number(price.replace(" ", ""))
                totalBonus = totalBonus + bonus

                allItems.push(thisJson)
            })
        }

        localStorage.setItem("orderItems", JSON.stringify(allItems))
        window.location.href = "/cart/order"
    }

    return (
        <div class="item-view-section-container">
            <div class="item-view-price-row">
                { method === "cash" ? <>{total}</> : <>{Number(total.replace(" ", "")) + Number(total.replace(" ", "")) / 100 * 10}</>}
                
                {" ₽"} <div class="item-view-price-bonus"><ProceedBonusSign price={total}/></div>
            </div>
            <div class="item-view-card-price">
                { method === "cash" ? <div> 
                    Цена по карте: {Number(total.replace(" ", "")) + Number(total.replace(" ", "")) / 100 * 7} ₽
                </div> : <div> 
                    Цена за наличные: {Number(total.replace(" ", ""))} ₽
                </div> }
            </div>
            <div class="payment-method-selector">
                {method === "cash" ? <WideButtonSelected>Наличными</WideButtonSelected>  : <WideButton onClick={() => {setMethod("cash")}}>Наличными</WideButton> }
                <span/>
                {method === "card" ? <WideButtonSelected>Картой</WideButtonSelected>  : <WideButton onClick={() => {setMethod("card")}}>Картой</WideButton> }
            </div>
            <div class="transfer-warning">
                Если вы хотите оплатить переводом на карту, тогда выбирайте метод оплаты "Наличные"
            </div>
            <SectionButton onClick={() => {handleProceed()}}>
                Продолжить
            </SectionButton>
        </div>
    )
}

function ProceedBonusSign({price}) {
    const [ bonusAmount, setBonusAmount ] = useState("")  

    useEffect(() => {
        var intPrice = parseInt(Number(price.replace(" ", "")) / 300)
        setBonusAmount(intPrice)
    }, [price])

    return(
        <div class="bonus-sign">
            <img src={bonus} alt=""/>
            <div class="bonus-sign-text">
                {String(Number(bonusAmount) - 1)}
            </div>
        </div>
    )
}

function CreditSection() {
    const [creditCart, setCreditCart] = useState([])

    useEffect(() => {
        var cartObject = JSON.parse(localStorage.getItem("cart"))
        console.log("credit credit")
        console.log(cartObject)

        axios.post("https://phoneplaceserver.ru/items/formCreditCart", {"cart": cartObject}).then((response) => {
            setCreditCart(response.data["response"])
        })
    }, [])


    return (
        <>
        { creditCart.length > 0 ? 

            <div class="item-view-section-container">
            <div class="item-view-price-row">
                Можно купить в кредит
            </div>
            <span/>
            <button type="button" className="VD_BUTTON WITH_LABEL" onClick={() => vd.create({
            partnerCode: 'S0106186', // Выдается нашим менеджером
            orderNum:'S0106186',
            partnerProductCode:'credit',
            view:'self',
            items: creditCart
            })}
            ></button>
            <div class="transfer-warning">
                До 36 месяцев
            </div>
        </div> : <div></div>

        }
        </>
    )
}

